import React from "react";

interface Props {
    label: string;
    loading: boolean;
    disabled?: boolean;
    onClick: (arg0: any) => void;
}

const CustomButton: React.FC<Props> = ({
    label,
    loading,
    disabled,
    onClick,
}) => {
    const customClassName = loading ? "flex items-center justify-center bg-gray-500 rounded-xl py-3 w-20 border-white border-3 text-white mt-1"
        :
        disabled ? "bg-gray-500 rounded-xl py-3 px-7 border-white border-3 text-white"
            :
            "bg-moderna_yellow font-main font-bold rounded-xl py-3 px-7 border-white border-3 text-white text-base"
    return (
        <div>
            <button disabled={loading || disabled} onClick={onClick} className={customClassName}>
                {!loading ? (<span>{label}</span>)
                    :
                    (<svg className="w-5 animate-spin -ml-1 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.373A8"></path>
                    </svg>
                    )
                }
            </button>
        </div >
    );
};

export default CustomButton;
