import { Button, Image, Modal, ModalContent, ModalHeader, Spinner } from "@nextui-org/react";
import { useContext, useEffect, useRef, useState } from "react";
import { SessionContext } from "../../../modules/authentication/context/SessionProvider";
import style from "./css/style.module.css";
import prueba from "../../../assets/images/prueba.png";
import club from "../../../assets/images/club.jpg";
import auspicio from "../../../assets/images/auspicio.jpg";
import footer from "../../../assets/images/footerinicio.jpg";
import LogoModerna from "../../../assets/images/Logo.png";
import { ERROR_TYPES } from "../../../modules/authentication/interfaces/ISession.d";
import EditableModals from "../../../component/Modal/ReactModalEditable";

export default function SignIn() {
  const { signInAdmin, signOut, error } = useContext(SessionContext);
  const buttonRef = useRef<any>();
  const hasClickRef = useRef<boolean>(false);
  const [modalOpen, setModalOpen] = useState(true)
  const [modalOpenCargado, setModalOpenCargado] = useState(false)

  async function handleSignIn() {
    try {
      setModalOpenCargado(true);
      const valueHandle = await signInAdmin();
      hasClickRef.current = valueHandle.result;
    } catch (error) {
    } finally {
      setModalOpenCargado(false);
    }

  }

  useEffect(() => {
    const timerId = setTimeout(() => {
      buttonRef.current?.click();

    }, 500);

    return () => clearTimeout(timerId);
  }, []);

  if (error?.type === ERROR_TYPES.NOT_ADMIN) {
    return (<Modal
      size={"lg"}
      isOpen={modalOpen}
      onClose={() => { setModalOpen(false), signOut() }}
      scrollBehavior="inside"
    >
      <ModalContent>
        <ModalHeader className="flex flex-col gap-1 justify-center items-center">
          <Image src={LogoModerna} width={200} height={100} />
          <h1 className="flex justify-center my-2">{error.message}</h1>
        </ModalHeader>
        <div className="flex justify-center mb-5 font-main">
          <Button className="bg-moderna_red text-white" onClick={() => { setModalOpen(false), signOut() }}>Aceptar</Button>
        </div>
      </ModalContent>
    </Modal>)
  }

  return (
    <div className={`flex flex-col bg-moderna_red h-screen justify-between items-center ${style.principal} `}>
      <div className={`headerContainer ${style.headerContainer} flex justify-center relative`}>
        <img className="z-[1]" src={prueba} alt="Prueba" />
        <div className="h-[2.9rem] shadow-xl bg-moderna_yellow w-full absolute bottom-0 hidden 2xl:block" />
      </div>
      <div className="h-24">
        <img className="h-full" src={club} alt="Club" />
      </div>


      <form className="flex flex-col items-center space-y-3 mb-3">
        <div>
          <label className={`${style["txt-btn-modal-content"]} max-[306px]:text-center flex`}>
            Administrador Moderna Alimentos
          </label>
        </div>

        <div className="h-unit-3xl object-cover mt-20">
          <img className="h-full" src={auspicio} alt="Auspicio" />
        </div>

        <div>
          <Button className="bg-moderna_yellow rounded-xl py-1 px-7 border-white border-3 text-white" ref={buttonRef} onClick={handleSignIn}>Iniciar Admin</Button>
        </div>

      </form >



      <div className="flex flex-col items-center bg-white w-full">
        <img className="h-unit-2xl object-cover" src={footer} alt="Footer" />
      </div>
      <EditableModals
        isOpen={modalOpenCargado}
        classname="w-[35vh] h-[20vh]"
        body={
          <div className="flex justify-center items-center">
            <Spinner label="Cargando..." color="warning" size="lg" className="font-main" />
          </div >}
        isDismissable={true}
        isKeyboardDismissDisabled={false}
      />
    </div >
  );
}
