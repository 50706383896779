import { makeRequest } from "../../common/request";
import { URL_QUERY_OPERATIONS } from "../serviceurl";

export const updateStateAccountInDB = async (
  millas_disponibles_canje: number,
  conditionValue: number
) => {
  try {
    const response = await makeRequest("POST", URL_QUERY_OPERATIONS, null, {
      operation: "U",
      table: "gmc_estado_cuenta",
      field: ["millas_disponibles_canje", "au_fecha_modificacion"],
      value: [millas_disponibles_canje, "DATEADD(hour, -5, GETDATE())"],
      conditionField: "id_estado_cuenta",
      condition: "=",
      conditionValue: conditionValue,
    });
    return response;
  } catch (error) {
  }
};
