import React, { ReactNode } from "react";
import {
    Modal,
    ModalContent,
    ModalHeader,
    Button,
    ModalBody,
    ModalFooter,
} from "@nextui-org/react";
import logoModerna from "../../assets/images/Logo.png";

interface CustomModalProps {
    isOpen: boolean;
    onClose: () => void;
    body?: () => ReactNode;
    footer?: () => ReactNode;
    title?: string;
    message?: string;
    onAccept?: () => void;
    onCloseEvent?: () => void;
    logo?: string;
    onlyCloseButton?: boolean;
    onOpenChange?: (open: boolean) => void;
    disabled?: boolean;
    classname?: string;
    canceleDisable?: boolean;
    dismissable?: boolean;
    noExit?: boolean
    width?: string
}

const CustomModal: React.FC<CustomModalProps> = ({
    isOpen,
    onClose,
    body,
    footer,
    title,
    onAccept,
    onOpenChange,
    onCloseEvent,
    dismissable,
    message,
    logo,
    onlyCloseButton = false,
    classname,
    canceleDisable,
    noExit,
    width
}) => {
    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            scrollBehavior="inside"
            className={` ${classname}`}
            placement="center"
            onOpenChange={onOpenChange}
            isDismissable={dismissable ? false : true}
            closeButton={noExit && <></>}
        >
            <ModalContent>
                <ModalHeader className="flex justify-center items-center ">
                    <div className="flex justify-center w-full">
                        <img
                            alt="Logo moderna"
                            src={logo ? logo : logoModerna}
                            width={width ? width :"40%"}
                            className="flex justify-center"
                        />
                    </div>
                </ModalHeader>

                <ModalBody className="flex justify-center text-center p-0 m-0">
                    {body ? (
                        body()
                    ) : (
                        <div>
                            {title && (
                                <h1 className="text-lg font-medium p-0 px-8 m-0 font-main">{title}</h1>
                            )}
                            {message && <h4 className="p-0 m-0 px-4 font-main">{message}</h4>}
                        </div>
                    )}
                </ModalBody>
                <ModalFooter className="flex justify-around pb-0 font-main">
                    {body ? null : (
                        <div className="flex justify-center mb-5 w-full gap-8 ">
                            {onlyCloseButton ? ( 
                                <Button
                                    className="bg-moderna_red text-white w-1/3"
                                    onClick={() => {
                                        onClose()
                                        onCloseEvent && onCloseEvent()
                                    }}
                                >
                                    Aceptar
                                </Button>
                            ) : (
                                <>
                                    {" "}

                                    {canceleDisable ? null :
                                        <Button
                                            className="bg-moderna_red text-white w-1/3"
                                            onClick={onClose}
                                        >
                                            Cancelar
                                        </Button>}
                                    <Button
                                        className="bg-moderna_yellow text-white w-1/3"
                                        onClick={onAccept}
                                    >
                                        Aceptar
                                    </Button>
                                </>
                            )}
                        </div>
                    )}
                    {footer && footer()}
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default CustomModal;