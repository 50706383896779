import Header from "../../component/Header/header";
import footer from "../../assets/images/LogoBlanco.png";
import React, { useContext, useEffect, useState } from "react";
import { Spinner } from "@nextui-org/react";
import { PDFViewer } from "./GoogleDrivePDFViewer";
import { getFile } from "../../services/images/getFile";
import CustomButton from "../../component/Button/Button";
import { SessionContext } from "../../modules/authentication/context/SessionProvider";
import { getPdfFileName } from "../../services/pdf/getPdfFileName";
import { ProvisionalData } from "../otp/otp";
import { getClientInDB } from "../../services/clientes/getClientInDB";

export default function TermsConditions() {
  const [accepted, setAccepted] = useState(false);
  const [pdfFile, setPdfFile] = useState<string>("");
  const [loadingPdf, setLoadingPdf] = useState<boolean>(false);
  const { handleAceptTYC, loading, setLoading, user, handleLogin } =
    useContext(SessionContext);

  useEffect(() => {
    handleSherepoint();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSherepoint = async () => {
    setLoadingPdf(true);
    const fileName = await handleGetFileName();
    const flie = await getFile(
      "Terminos y Condiciones/" + fileName,
      "application/pdf"
    );
    setLoadingPdf(false);
    flie && setPdfFile(flie);
  };
  const handleGetFileName = async () => {
    const fileList = await getPdfFileName();
    const recordset = fileList?.data?.recordset;
    recordset.sort((a: any, b: any) => {
      return (
        new Date(b.au_fecha_creacion).getTime() -
        new Date(a.au_fecha_creacion).getTime()
      );
    });
    const fileName = recordset[0].nombre;
    return fileName;
  };

  const handleCheckboxChange = (accepted: boolean) => {
    setAccepted(accepted);
  };

  function getProvisionalData(): ProvisionalData | null {
    const provitionalDataString = localStorage.getItem("provitionalData");
    if (typeof provitionalDataString === "string") {
      try {
        return JSON.parse(provitionalDataString);
      } catch (error) {
        return null;
      }
    }
    return null;
  }

  const handleSingIn = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const provitionalData = getProvisionalData();
    setLoading(true);
    if (provitionalData?.id_cliente) {
      await handleAceptTYC(provitionalData?.id_cliente || 0);
      await handleVerifyData(provitionalData?.id_cliente || 0);
    }

    setLoading(false);
  };

  const handleVerifyData = async (id_cliente: number) => {
    try {
      const response = await getClientInDB(id_cliente);
      if (response?.status == 200) {
        await handleLogin(response?.data[0]);
      }
    } catch (e) {
    }
  };

  return (
    <div className="flex flex-col justify-between">
      <div>
        <div className="w-full h-auto">
          <Header />
        </div>

        <div className="flex justify-end w-screen bg-moderna_yellow">
          <div className=" bg-moderna_yellow shadow-2xl border-1 border-black  border-opacity-20 rounded-tl-2xl flex justify-center items-center">
            <h1 className="text-white md:text-4xl text-xl mx-unit-xl my-unit-md">
              Términos y condiciones{" "}
            </h1>
          </div>
        </div>
      </div>
      <div
        id="wrap"
        style={{
          height: "625px",
        }}
        className="overflow-y-auto overflow-x-hidden"
      >
        <div>
          <label className="">
            {loadingPdf ? (
              <div className="w-full flex flex-col justify-center items-center my-unit-4xl">
                <Spinner
                  label="Cargando..."
                  color="warning"
                  size="lg"
                  className="font-main"
                />
              </div>
            ) : pdfFile ? (
              <div className={` w-full`}>
                <PDFViewer
                  fileId={pdfFile}
                  handleCheckboxChange={handleCheckboxChange}
                  accepted={accepted}
                />
              </div>
            ) : (
              <div className="w-full flex flex-col justify-center items-center my-unit-4xl">
                <h1>
                  Hubo un problema para cargar el documento, inténtelo más tarde
                </h1>
              </div>
            )}
          </label>
        </div>
      </div>

      <div className="flex flex-col items-center">
        <CustomButton
          onClick={handleSingIn}
          loading={loading}
          disabled={!accepted}
          label="Continuar"
        />
      </div>

      <div className="flex flex-col items-end  w-full h-14 bg-moderna_red">
        <img className="h-unit-2xl object-cover" src={footer} />
      </div>
    </div>
  );
}
