export type ISession = SessionMethods & IReducer;

export type SessionMethods = {
    sendCode: (agr0: string) => any;
    signIn: (agr0: boolean) => any;
    signOut: (arg0?: boolean) => any;
    setLoading: (arg0: boolean) => any;
    handleVerifyByID: (arg0: string) => any;
};

export interface IReducer {
    clients: Clients[] | null | any;
    editableClients: Clients | null;
}

export type Clients = {
    id_usuario: number;
    id_rol?: [];
    correo: string;
    au_id_usuario_creacion?: [];
    au_fecha_modificacion: [];
    au_fecha_creacion: [];
    nombre_rol: string;
};


export enum ActionTypes {
    SET_CLIENTS = "SET CLIENTS",
    SET_EDIT_CLIENTS = "SET EDIT CLIENTS",
}

export type ReducerAction =
    | { action: ActionTypes.SET_PRODUCTS; payload: Product[] }
    | {
        action: ActionTypes.SET_EDIT_PRODUCT;
        payload: Product;
    };
