import axios from "axios";

const instance = axios.create({
    timeout: 5000,
    headers: {
        'Content-Type': 'application/json'
    }
});


export const postAxios = async (url: string, body: object) => {
    const controller = new AbortController();
    const signal = controller.signal;

    const timeoutId = setTimeout(() => {
        controller.abort();
    }, 60000);

    try {
        const response = await instance.post(url, body, { signal });
        clearTimeout(timeoutId);
        return response.data;
    } catch (error) {
        clearTimeout(timeoutId);
        throw Error("message");
    }
};

