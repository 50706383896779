import axios from "axios";
export async function getFile(
  fileName: string,
  format: string
): Promise<string | undefined> {

  const urlImagen =
    "https://modernaalimentos.sharepoint.com/sites/RepositorioApps/_api/web/GetFileByServerRelativeUrl('/sites/RepositorioApps/PaniMillas/" +
    fileName +
    "')/$value";
  const access_token = localStorage.getItem("SHAREPOINT_TOKEN");
  const headers = {
    Authorization: `Bearer ${access_token}`,
    "Content-Type": "application/json",
  };

  return new Promise((resolve, reject) => {
    setTimeout(async () => {
      try {
        const response = await axios.get(urlImagen, {
          responseType: "arraybuffer",
          headers,
        });

        if (response.status === 200) {
          const blob = new Blob([response.data], { type: format });
          const imageUrl = URL.createObjectURL(blob);
          resolve(imageUrl);
        } else {
          reject(
            new Error(`Error al acceder al archivo, status: ${response.status}`)
          );
        }
      } catch (error) {
        reject(error);
      }
    }, 2500); 
  });
}
