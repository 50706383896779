import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./moderna.css";
import { NextUIProvider } from "@nextui-org/react";
import { SessionProvider } from "./modules/authentication/context/SessionProvider";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import msal from "./modules/authentication/config/ad-config";

const msalInstance = new PublicClientApplication(msal.config);
import { ProductsProvider } from "./modules/products/context/ProductsProvider";
import { ClientsProvider } from "./modules/clientes/context/ClientsProvider";
import { BonusProvider } from "./modules/bonus/context/BonusProvider";
import { BrowserRouter } from "react-router-dom";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // <React.StrictMode>
    <BrowserRouter>
      <NextUIProvider>
        <MsalProvider instance={msalInstance}>
          <SessionProvider>
            <ProductsProvider>
              <ClientsProvider>
                <BonusProvider>
                  <App />
                </BonusProvider>
              </ClientsProvider>
            </ProductsProvider>
          </SessionProvider>
        </MsalProvider>
      </NextUIProvider>
    </BrowserRouter>
  // </React.StrictMode>
);

reportWebVitals();
