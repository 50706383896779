export type ISession = SessionMethods & IReducer;

export type SessionMethods = {
  sendCode: (agr0: string) => any;
  signIn: (agr0: boolean) => any;
  signOut: (arg0?: boolean) => any;
  setLoading: (arg0: boolean) => any;
  handleVerifyByID: (arg0: string) => any;
};

export interface IReducer {
  bonus: Bonus[] | null | any;
  editableBonus: Bonus | null;
}

export type Bonus = {
  id_bonus?: number;
  titulo?: string;
  panimillas?: string;
  unidad?: string;
  tipo?: string;
  bonus_cinco?: string;
  au_id_usuario_creacion: string;
  au_fecha_modificacion: string;
  au_fecha_creacion: string;
};

export enum ActionTypes {
  SET_BONUS = "SET BONUS",
  SET_EDIT_BONUS = "SET EDIT BONUS",
}

export type ReducerAction =
  | { action: ActionTypes.SET_BONUS; payload: Bonus[] }
  | {
    action: ActionTypes.SET_EDIT_BONUS;
    payload: Bonus;
  };
