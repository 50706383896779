import { useEffect, useReducer } from "react";
import {
  ACTION_TYPES,
  DataModal,
  IError,
  IReducer,
  ReducerAction,
  Roles,
  User,
} from "../interfaces/ISession.d";
import { auth } from "../service/auth-service";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import msal from "../config/ad-config";
import { useNavigate } from "react-router-dom";
import { sendOtp, validOtp } from "../../../services/otp/sendOtp";
import { updateAceptacion_tyc } from "../../../services/pdf/updateAceptacion_tyc";
import { updateSesion } from "../../../services/sesion/updateSesion";
import { getToken } from "../../../services/token/getToken";
import { queryStateAccount } from "../service/services";

function reducer(state: IReducer, reducerAction: ReducerAction) {
  const { action } = reducerAction;
  switch (action) {
    case ACTION_TYPES.LOG_OUT: {
      return {
        ...state,
        isSignedIn: false,
        isValidDNI: false,
        isValidOTP: false,
        user: null,
        loading: false,
        error: null,
      };
    }
    case ACTION_TYPES.SET_USER: {
      return {
        ...state,
        loading: false,
        error: null,
        isSignedIn: true,
        user: reducerAction.payload,
      };
    }
    case ACTION_TYPES.SET_LOADING: {
      return {
        ...state,
        loading: reducerAction.payload,
        error: null,
      };
    }
    case ACTION_TYPES.SET_LOGIN: {
      return {
        ...state,
        isSignedIn: reducerAction.payload,
      };
    }
    case ACTION_TYPES.SET_MODAL_STATUS: {
      return {
        ...state,
        dataModal: reducerAction.payload,
      };
    }
    case ACTION_TYPES.SET_ERROR: {
      return {
        ...state,
        error: reducerAction.payload,
        loading: false,
      };
    }
    case ACTION_TYPES.SET_SETUP: {
      return {
        ...state,
        setup: { ...state.setup, ...reducerAction.payload },
      };
    }
    case ACTION_TYPES.SET_PROVITIONAL_DATA: {
      return {
        ...state,
        provitionalData: reducerAction.payload,
      };
    }
    case ACTION_TYPES.SET_VALID_OTP: {
      return {
        ...state,
        isValidOTP: reducerAction.payload,
      };
    }
    case ACTION_TYPES.SET_VALID_DNI: {
      return {
        ...state,
        isValidDNI: reducerAction.payload,
      };
    }
    case ACTION_TYPES.SET_SESSION_ACTIVE: {
      return {
        ...state,
        isSessionActive: reducerAction.payload,
      };
    }
    default: {
      return { ...state };
    }
  }
}

const initialState: IReducer = {
  error: null,
  loading: false,
  setup: { admin: false, user: false },
  user: null,
  isSignedIn: false,
  isValidOTP: false,
  isValidDNI: false,
  isSessionActive: null,
  provitionalData: { telefono: null, id_cliente: null },
  dataModal: { state: false, body: null, header: null, onClick: null },
};

export default function useSession() {
  const navigate = useNavigate();
  const isAuthenticated = useIsAuthenticated();
  const [
    {
      error,
      isSignedIn,
      isValidDNI,
      isValidOTP,
      loading,
      user,
      dataModal,
      setup,
      isSessionActive,
      provitionalData,
    },
    dispatch,
  ] = useReducer(reducer, initialState);
  const { instance } = useMsal();

  function sendCode(identification: string) {
    auth.sendCodeUser("");
  }

  useEffect(() => {
    verifySignIn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function handleVerifyByID(identificacion: string) {
    setLoading(true);
    try {
      const sendOTPResponse = await handleSendOtp(identificacion);
      if (sendOTPResponse?.status !== 200) {
        return sendOTPResponse?.data.message;
      }
    } catch (e) {
      return e;
    } finally {
      setLoading(false);
    }

    setLoading(false);
  }

  function setupFunction(arg: { user: boolean } | { admin: boolean }) {
    dispatch({ action: ACTION_TYPES.SET_SETUP, payload: arg });
  }

  function handleSetSessionActive(state: boolean) {
    dispatch({ action: ACTION_TYPES.SET_SESSION_ACTIVE, payload: state });
  }

  async function handleSendOtp(identification: string) {
    try {
      const result = await sendOtp(identification);
      if (result?.status == 200) {
        let data = {
          ...JSON.parse(result.data?.userData),
          identificacion: identification,
        };
        localStorage.setItem("provitionalData", JSON.stringify(data));
        saveSharepoinToken();
        navigate("/validacion");
      } else {
        return result;
      }
    } catch (e) {
    }
  }

  async function handleUpdateStateAccount() {
    let stateAccount = await queryStateAccount(user?.id_cliente);
    localStorage.setItem("user", JSON.stringify({ ...user, ...stateAccount }));
    setUser({
      ...user,
      ...stateAccount,
      role: Roles.USER,
    });
  }

  async function handleValidOtp(idClient: number, otp: string) {
    setLoading(true);
    const result = await validOtp(idClient, otp);
    if (result?.status == 200) {
      const { userData } = result.data;
      let formatUserData = JSON.parse(userData);
      if (formatUserData?.fecha_aceptacion_tyc) {
        await handleLogin(formatUserData);
      } else {
        navigate("/terminosCondiciones");
      }
    } else {
      let message;
      try {
        message = JSON.parse(result?.data)?.message;
      } catch (error) {
        message = result?.data?.message;
      } finally {
        return message
          ? message
          : "Se han encontrado problemas de red al procesar tu solicitud. Por favor, intentalo más tarde.";
      }
    }
    setLoading(false);
  }

  async function handleLogin(user: User) {
    localStorage.setItem("isLoggedIn", "true");
    handleSetSessionActive(true);
    let stateAccount = await queryStateAccount(user?.id_cliente);
    await updateSesion("CLI", 1, user?.id_cliente);
    saveSharepoinToken();
    localStorage.setItem("user", JSON.stringify({ ...user, ...stateAccount }));
    setUser({
      ...user,
      ...stateAccount,
      role: Roles.USER,
    });
    navigate("/");
  }

  async function handleAceptTYC(id_cliente: number) {
    await updateAceptacion_tyc(id_cliente);
  }

  const verifySignIn = async () => {
    const isLoggedIn = localStorage.getItem("isLoggedIn") === "true";
    const userData = localStorage.getItem("user");

    if (Boolean(isLoggedIn) && userData) {
      saveSharepoinToken();
      signIn(true);
      handleSetSessionActive(true);
      let formatData = JSON.parse(userData);
      let stateAccount = await queryStateAccount(formatData?.id_cliente);
      localStorage.setItem(
        "user",
        JSON.stringify({ ...formatData, ...stateAccount })
      );
      setUser({ ...formatData, ...stateAccount, role: Roles.USER });
    } else {
      signIn(false);
      navigate("/");
    }
    setupFunction({ user: true });
  };

  function signIn(state: boolean) {
    dispatch({
      action: ACTION_TYPES.SET_LOGIN,
      payload: state,
    });
  }

  function setLoading(state: boolean) {
    dispatch({
      action: ACTION_TYPES.SET_LOADING,
      payload: state,
    });
  }

  function handleOpenModal(data: DataModal) {
    dispatch({
      action: ACTION_TYPES.SET_MODAL_STATUS,
      payload: data,
    });
  }

  const reloadSession = () => {
    dispatch({ action: ACTION_TYPES.LOG_OUT });
    navigate("/");
  };

  async function signOut(isSessionExpired?: boolean) {
    setLoading(true);
    const userString = localStorage.getItem("user");
    const user: User | null = userString ? JSON.parse(userString) : null;
    const table = isAuthenticated ? "ADMIN" : "CLI";

    if (table === "CLI") {
      try {
        if (!user) {
          localStorage.clear();
          reloadSession();
          return; 
        }

        const valUpdatew = await updateSesion(table, 0, user?.id_cliente);
        if (valUpdatew?.statusText === "OK") {
          localStorage.removeItem("isLoggedIn");
          localStorage.removeItem("user");
          localStorage.removeItem("SHAREPOINT_TOKEN");
          localStorage.clear();

          if (!isSessionExpired) {
            reloadSession();
          }
        }
      } catch (e) {
      }
    }

    if (table === "ADMIN") {
      if (isAuthenticated) {
        try {
          await instance.logout();
          navigate("/");
        } catch (e: any) {
          dispatch({ action: ACTION_TYPES.SET_ERROR, payload: e.message });
        }
      }
    }
    setLoading(false);
  }

  async function signInAdmin() {
    try {
      await instance.loginPopup(msal.request);
      await updateSesion("ADMIN", 1, user?.id_cliente);
      saveSharepoinToken();
      return { result: true };
    } catch (error) {
      return { result: false };
    }
  }

  async function saveSharepoinToken() {
    let token = await getToken();
    localStorage.setItem("SHAREPOINT_TOKEN", token?.data?.access_token);
  }

  function setUser(user: User | null) {
    dispatch({ action: ACTION_TYPES.SET_USER, payload: user });
  }

  function setError(error?: IError) {
    dispatch({ action: ACTION_TYPES.SET_ERROR, payload: error || null });
  }

  return {
    signIn,
    sendCode,
    signOut,
    error,
    signInAdmin,
    isSignedIn,
    isValidOTP,
    isValidDNI,
    loading,
    user,
    isSessionActive,
    provitionalData,
    setUser,
    setLoading,
    handleVerifyByID,
    handleSendOtp,
    saveSharepoinToken,
    handleUpdateStateAccount,
    handleOpenModal,
    handleValidOtp,
    handleLogin,
    handleAceptTYC,
    dataModal,
    setError,
    setup,
    setupFunction,
    handleSetSessionActive,
  };
}