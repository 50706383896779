import { useEffect, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import ControlPanel from "./ControlPanel";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { Checkbox } from "@nextui-org/react";
import StyledText from "../../../component/Text/text";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PDFReader = ({
  file,
  accepted,
  handleCheckboxChange,
  disableCheckBox,
}: {
  file: any;
  accepted: boolean;
  disableCheckBox?: boolean;
  handleCheckboxChange?: any;
}) => {
  const [scale, setScale] = useState(1);
  const [numPages, setNumPages] = useState<number | null>(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [isClicked, setIsClicked] = useState(false);

  useEffect(() => {
    function updateScale() {
      const windowWidth = window.innerWidth;
      const maxScale = 1;
      const minScale = 0.5;
      const screenWidthThreshold = 768;

      if (windowWidth < screenWidthThreshold) {
        setScale(0.5);
      } else {
        const calculatedScale = windowWidth / screenWidthThreshold;
        setScale(Math.min(Math.max(calculatedScale, minScale), maxScale));
      }
    }
    window.addEventListener("resize", updateScale);
    updateScale();
    return () => window.removeEventListener("resize", updateScale);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onDocumentLoadSuccess({
    numPages,
  }: {
    numPages: number;
    disableCheckBox?: boolean;
  }) {
    setNumPages(numPages);
    setIsLoading(false);
  }

  useEffect(() => {
    if (isClicked) {
      handleCheckboxChange(isClicked);
    } else {
      handleCheckboxChange(isClicked);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isClicked]);

  return (
    <div className="w-full">
      <section className="flex flex-col items-center justify-center w-full ">
        <ControlPanel
          scale={scale}
          setScale={setScale}
          numPages={numPages}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          file={file}
        />
        <Document
          file={file}
          onLoadSuccess={onDocumentLoadSuccess}
          className={
            "flex w-24 justify-center items-center h-auto border border-gray-300 shadow-md mb-2"
          }
        >
          <Page
            pageNumber={pageNumber}
            scale={scale}
            renderMode="canvas"
          />
        </Document>
      </section>

      {!disableCheckBox && numPages === pageNumber && (
        <div className="flex items-center justify-center my-10 space-x-2 ">
          <Checkbox
            isSelected={accepted}
            onClick={() => {
              setIsClicked(!isClicked);
            }}
            color="primary"
            className="text-white"
          >
            <StyledText>Acepta términos y condiciones</StyledText>
          </Checkbox>
        </div>
      )}
    </div>
  );
};

export default PDFReader;
