import style from "./css/style.module.css";
import logo from "../../assets/images/logo-blanco.png";
import { useLocation } from "react-router";
const BANNED_ROUTES = ["/", "/*"];

export default function Footer() {
  const location = useLocation();
  if (BANNED_ROUTES.includes(location.pathname)) {
    return null;
  }
  return (
    <footer className={`${style["contenedor"]}`} >
      <div className={`${style["contenedor-img"]}`}>
        <img src={logo} alt="" />
      </div>
    </footer>

  );
}
