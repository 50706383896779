import { makeRequest } from "../../common/request";
import { URL_QUERY_OPERATIONS } from "../serviceurl";
export const LIMIT_PRODUCTS = 20;

export const getAllProductsInDB = async (limit: number) => {
  try {
    const response = await makeRequest("POST", URL_QUERY_OPERATIONS, null, {
      operation: "S",
      table: "gmc_premio",
      join: "inner",
      commonField: "id_categoria",
      joinTable: "gmc_categoria",
      requiredFields: ["nombre_categoria"],
      order_by: "nombre_premio",
      offset: limit - 10,
      limit: 10,
    });
    return response;
  } catch (error) {
  }
};

export async function fetchProductsDB({
  page,
  categoryID,
  keyword,
  isProveedor,
  isAdmin,
}: {
  categoryID?: number | string | null;
  keyword?: string | null;
  page: number;
  isProveedor?: number | null;
  isAdmin?: boolean;
}) {
  try {
    const conditions = [];
    const conditionValues: any[] = [];
    const conditionFields = [];
    if (!isAdmin) {
      conditions.push("=");
      conditionValues.push(1);
      conditionFields.push("gmc_premio.estado");
    }
    if (categoryID || categoryID === 0) {
      conditions.push("=");
      conditionValues.push(categoryID);
      conditionFields.push("gmc_premio.id_categoria");
    }
    if (isProveedor || isProveedor === 0) {
      conditions.push("=");
      conditionValues.push(isProveedor);
      conditionFields.push("gmc_premio.es_externo");
    }
    if (keyword) {
      conditions.push("LIKE");
      conditionValues.push(`'%${keyword}%'`);
      conditionFields.push("gmc_premio.nombre_premio");
    }
    const response = await makeRequest("POST", URL_QUERY_OPERATIONS, null, {
      operation: "S",
      table: "gmc_premio",
      join: "inner",
      commonField: "id_categoria",
      joinTable: "gmc_categoria",
      requiredFields: ["nombre_categoria"],
      order_by: "nombre_premio",
      offset: LIMIT_PRODUCTS * page,
      limit: LIMIT_PRODUCTS,
      condition: conditions.length > 0 ? conditions : undefined,
      conditionValue: conditionValues.length > 0 ? conditionValues : undefined,
      conditionField: conditionFields.length > 0 ? conditionFields : undefined,
    });
    if (response.status !== 200) {
      throw Error(response.statusText);
    }
    return {
      result: true,
      data: response.data.recordset,
      total: response.data.totalRecordset,
      error: null,
    };
  } catch (error) {
    return {
      result: false,
      data: null,
      total: null,
      error,
    };
  }
}
