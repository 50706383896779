import { useReducer } from "react";
import {
  ActionTypes,
  IReducer,
  Product,
  ReducerAction,
} from "../interfaces/IProducts.d";
import { getAllProductsInDB } from "../../../services/products/getAllProductsInDB";
import { getAllCategoriesInDB } from "../../../services/categories/getAllCategoriesInDB";
import { getAllRolInDB } from "../../../services/rol/getAllRolInDB";

function reducer(state: IReducer, reducerAction: ReducerAction) {
  const { action } = reducerAction;
  switch (action) {
    case ActionTypes.SET_PRODUCTS: {
      return { ...state, products: reducerAction.payload };
    }
    case ActionTypes.SET_EDIT_PRODUCT: {
      return { ...state, editableProduct: reducerAction.payload };
    }
    case ActionTypes.SET_CATEGORIES: {
      return { ...state, categories: reducerAction.payload };
    }
    case ActionTypes.SET_ROL: {
      return { ...state, rol: reducerAction.payload };
    }
    case ActionTypes.SET_LOADING: {
      return { ...state, loading: reducerAction.payload };
    }
    case ActionTypes.SET_LIMIT: {
      return { ...state, limit: reducerAction.payload };
    }
    case ActionTypes.SET_HAS_MORE_DATA: {
      return { ...state, hasMoreData: reducerAction.payload };
    }
    case ActionTypes.SET_TOTAL_PRODUCTS_SIZE: {
      return { ...state, totalProductsSize: reducerAction.payload };
    }
    default: {
      return { ...state };
    }
  }
}

const initialState: IReducer = {
  products: [],
  totalProductsSize: 0,
  editableProduct: null,
  categories: [],
  rol: [],
  limit: 10,
  hasMoreData: true,
  loading: false,
};

export default function useProducts() {
  const [
    {
      products,
      editableProduct,
      categories,
      rol,
      limit,
      loading,
      hasMoreData,
      totalProductsSize,
    },
    dispatch,
  ] = useReducer(reducer, initialState);

  const handleSetDBProducts = async (quantity?: number) => {
    try {
      setLoading(true);
      const allDBProducts = await getAllProductsInDB(
        quantity && typeof quantity === "number" ? quantity : limit
      );
      const totalProductsSize = allDBProducts?.data?.totalRecordset;
      const product_list = [...products, ...allDBProducts?.data?.recordset];
      if (totalProductsSize <= product_list.length) {
        setHasMoreData(false);
      }
      setTotalProductsSize(totalProductsSize);
      setLimit(quantity && typeof quantity === "number" ? limit : limit + 10);
      setProducts(product_list);
    } catch (e) {}
    setLoading(false);
  };

  const setProducts = (product_list: Product[]) => {
    dispatch({
      action: ActionTypes.SET_PRODUCTS,
      payload: product_list,
    });
  };

  const setTotalProductsSize = (size: number | null) => {
    dispatch({
      action: ActionTypes.SET_TOTAL_PRODUCTS_SIZE,
      payload: size,
    });
  };

  const handleSetDBCategories = async () => {
    try {
      const allDBCategories = await getAllCategoriesInDB();
      dispatch({
        action: ActionTypes.SET_CATEGORIES,
        payload: allDBCategories?.data?.recordset,
      });
    } catch (e) {}
  };
  const handleSetDBRol = async () => {
    const allDBRol = await getAllRolInDB();
    dispatch({
      action: ActionTypes.SET_ROL,
      payload: allDBRol?.data?.recordset,
    });
  };

  const setEditableProduct = (product: Product) => {
    dispatch({
      action: ActionTypes.SET_EDIT_PRODUCT,
      payload: product,
    });
  };
  const setLimit = (limit: number) => {
    dispatch({
      action: ActionTypes.SET_LIMIT,
      payload: limit,
    });
  };

  const setHasMoreData = (state: boolean) => {
    dispatch({
      action: ActionTypes.SET_HAS_MORE_DATA,
      payload: state,
    });
  };

  const setLoading = (state: boolean) => {
    dispatch({
      action: ActionTypes.SET_LOADING,
      payload: state,
    });
  };

  return {
    products,
    totalProductsSize,
    categories,
    rol,
    editableProduct,
    limit,
    hasMoreData,
    loading,
    setEditableProduct,
    handleSetDBProducts,
    handleSetDBCategories,
    handleSetDBRol,
    setProducts,
  };
}
