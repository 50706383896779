import { ReactNode } from "react";
import { JsxElement } from "typescript";

export type ISession = SessionMethods & IReducer;

export type SessionMethods = {
  sendCode: (agr0: string) => any;
  signIn: (agr0: boolean) => any;
  signOut: (arg0?: boolean) => any;
  setupFunction: (arg: { user: boolean } | { admin: boolean }) => any;
  setLoading: (arg0: boolean) => any;
  handleVerifyByID: (arg0: string) => any;
  handleSendOtp: (arg0: string) => any;
  handleValidOtp: (arg0: number, arg1: string) => any;
  handleLogin: (arg0: User) => any;
  handleAceptTYC: (arg0: number) => any;
  signInAdmin: () => any;
  handleUpdateStateAccount: () => any;
  setUser: (arg0: User | null) => any;
  handleOpenModal: (arg0: DataModal) => any;
  setError: (arg0?: IError) => any;
  handleSetSessionActive: (arg0: boolean) => any;
  saveSharepoinToken: () => any;
};

export enum Roles {
  USER = "user",
  ADMIN = "admin",
  MANAGER = "manager",
}

export type ISessionProvider = IReducer & SessionMethods;

export interface IReducer {
  isSignedIn: boolean;
  isValidOTP: boolean;
  isValidDNI: boolean;
  provitionalData: { telefono: string | null; id_cliente: number | null };
  user: User | null;
  loading: boolean;
  setup: { user: boolean; admin: boolean };
  error: null | IError;
  dataModal: DataModal;
  isSessionActive: boolean | null;
}

export type UserDefaults = {
  nombre: string;
  id_usuario: number;
};

export interface DataModal {
  state: boolean;
  body: (() => JSX.Element) | null | (() => Element) | any;
  header: (() => JSX.Element) | null;
  onClick: (() => {}) | (() => void) | null;
}

export interface IError {
  type: ERROR_TYPES;
  message: string;
  param?: any;
}

export type User =
  | (UserDefaults &
      (
        | {
            role: Roles.ADMIN | Roles.MANAGER;
            correo: string;
          }
        | {
            role: Roles.USER;
            identificacion: string;
            correo?: string;
          }
      ))
  | any;

export enum ACTION_TYPES {
  SEND_CODE = "SEND CODE",
  LOG_OUT = "LOG OUT",
  SET_USER = "SET USER",
  SET_ERROR = "ERROR",
  SET_LOADING = "LOADING",
  SET_LOGIN = "SET LOGIN",
  SET_MODAL_STATUS = "SET MODAL STATUS",
  SET_SETUP = "SET SETUP",
  SET_VALID_OTP = "SET VALID OTP",
  SET_VALID_DNI = "SET VALID DNI",
  SET_SESSION_ACTIVE = "SET SESSION ACTIVE",
  SET_PROVITIONAL_DATA = "SET_PROVITIONAL_DATA",
}

export enum ERROR_TYPES {
  NOT_ADMIN = "NOT ADMIN",
  LOG_OUT = "LOG OUT",
  NOT_PANIMILLAS = "NOT PANIMILLAS",
}

export type ReducerAction =
  | {
      action: ACTION_TYPES.LOG_OUT;
    }
  | {
      action: ACTION_TYPES.SET_USER;
      payload: User | null;
    }
  | {
      action: ACTION_TYPES.SET_LOADING;
      payload: boolean;
    }
  | {
      action: ACTION_TYPES.SET_LOGIN;
      payload: boolean;
    }
  | {
      action: ACTION_TYPES.SET_ERROR;
      payload: IError | null;
    }
  | {
      action: ACTION_TYPES.SET_MODAL_STATUS;
      payload: DataModal;
    }
  | {
      action: ACTION_TYPES.SET_SETUP;
      payload: { user: boolean } | { admin: boolean };
    }
  | {
      action: ACTION_TYPES.SET_VALID_OTP;
      payload: boolean;
    }
  | {
      action: ACTION_TYPES.SET_VALID_DNI;
      payload: boolean;
    }
  | {
      action: ACTION_TYPES.SET_SESSION_ACTIVE;
      payload: boolean;
    }
  | {
      action: ACTION_TYPES.SET_PROVITIONAL_DATA;
      payload: { telefono: string; id_cliente: number };
    };
