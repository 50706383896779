import { useContext, useEffect, useState } from "react";
import { Disclosure } from "@headlessui/react";
import {
  Bars3Icon,
  XMarkIcon,
  ArrowLeftEndOnRectangleIcon,
  UserCircleIcon,
} from "@heroicons/react/24/outline";
import { SessionContext } from "../../modules/authentication/context/SessionProvider";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { Roles } from "../../modules/authentication/interfaces/ISession.d";
import CustomModal from "../Modal/ModalCustom";
import { ClientsContext } from "../../modules/clientes/context/ClientsProvider";
import { SeparatorNumber } from "../../common/validations";
import StyledText from "../Text/text";
import { Button } from "@nextui-org/react";
import Framer from "../framer/framer";
import Icons from "../Icons/Icons";
import { Spinner } from "@nextui-org/react";

function classNames(...classes: (string | boolean | undefined)[]): string {
  return classes.filter(Boolean).join(" ");
}
const BANNED_ROUTES = ["/", "/*"];
const BACK_ROUTES = ["/canje"];

type NavBarProps = {
  title?: string;
  panimillas: number | string;
};

export default function Navbar() {
  const location = useLocation();
  const { clients } = useContext(ClientsContext);
  const [openModal, setOpenModal] = useState(false);
  const { signOut, user, loading } = useContext(SessionContext);

  const navigation = [
    {
      name: "Bonus",
      href: "/admin/listabonos",
      current: false,
      isEnable: user?.role === Roles.ADMIN || user?.role === Roles.MANAGER,
    },
    {
      name: "Premios",
      href: "/admin/listaproducto",
      current: false,
      isEnable: user?.role === Roles.ADMIN || user?.role === Roles.MANAGER,
    },
    {
      name: "Usuarios",
      href: "/admin/usuarios",
      current: false,
      isEnable: user?.role === Roles.MANAGER,
    },
    {
      name: "Términos y condiciones",
      href: "/admin/terminos-y-condiciones",
      current: false,
      isEnable: user?.role === Roles.ADMIN || user?.role === Roles.MANAGER,
    },
  ];
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

  const filtrarClientesPorId = (correo: any) => {
    return clients?.filter((client: any) => client?.correo === correo);
  };
  const usuarioID = filtrarClientesPorId(user?.correo);
  const showBackButton = BACK_ROUTES.includes(location.pathname);

  const id = {
    ...user,
    id_usuario_filtrado: usuarioID,
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  if (BANNED_ROUTES.includes(location.pathname)) {
    return null;
  }

  return (
    <div className="flex flex-row bg-moderna_red justify-center items-end realtive">
      <Disclosure as="nav" className="  w-full bg-moderna_yellow  z-[1]">
        {({ open }) => (
          <>
            <div className="flex flex-row justify-between">
              {showBackButton && (
                <Framer.Fade direction="in" className="flex px-4 items-center">
                  <Framer.Hover>
                    <Button
                      isIconOnly
                      className="no-opacity bg-slate-100"
                      as={Link}
                      to={"/"}
                    >
                      <Icons.Back size={30} />
                    </Button>
                  </Framer.Hover>
                </Framer.Fade>
              )}

              <div>
                <div className="mx-auto max-w-7xl px-2 sm:px-6  lg:px-8">
                  <div className="relative flex h-16 items-center justify-between">
                    <div className="inset-y-0 left-0 flex items-center sm-bg-red-500 md:">
                      {user?.role === Roles.ADMIN ||
                        (user?.role === Roles.MANAGER && (
                          <Disclosure.Button className="md:hidden lg:hidden  items-center justify-center rounded-md p-2 text-red-400 hover:bg-moderna_red hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                            <span className="absolute -inset-0.5" />
                            <span className="sr-only">Open main menu</span>
                            {open ? (
                              <XMarkIcon
                                color="white"
                                className="block h-6 w-6"
                                aria-hidden="true"
                              />
                            ) : (
                              <Bars3Icon
                                className="block h-6 w-6"
                                color="white"
                                aria-hidden="true"
                              />
                            )}
                          </Disclosure.Button>
                        ))}
                    </div>

                    <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                      {windowWidth >= 768 && (
                        <div className="flex items-center justify-between lg:block font-bold space-x-4">
                          {navigation.map(
                            (item) =>
                              item.isEnable && (
                                <Link
                                  key={item.name}
                                  to={item.href}
                                  className={`rounded-md px-3 py-2 text-sm font-medium text-white hover:bg-moderna_red hover:text-white ${
                                    item.href === location.pathname
                                      ? "bg-moderna_red text-white font-bold"
                                      : ""
                                  }`}
                                  aria-current={
                                    item.current ? "page" : undefined
                                  }
                                >
                                  {item.name}
                                </Link>
                              )
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <Disclosure.Panel
                  className={`sm:hidden 
                ${windowWidth >= 768 ? "hidden" : "block"}
                `}
                >
                  <div className="px-2 scroll-mt-px">
                    {navigation.map(
                      (item) =>
                        item.isEnable && (
                          <Disclosure.Button
                            key={item.name}
                            as={Link}
                            to={item.href}
                            className={classNames(
                              item.current
                                ? "bg-white text-white font-bold"
                                : "text-white-500 hover:bg-moderna_red hover:font-bold hover:text-white",
                              "block rounded-md px-3 py-2 text-base font-medium"
                            )}
                            aria-current={item.current ? "page" : undefined}
                          >
                            {item.name}
                          </Disclosure.Button>
                        )
                    )}
                  </div>
                </Disclosure.Panel>
              </div>
              {!open && (
                <div className="flex flex-row justify-between min-[300px]:w-80 md:w-96">
                  <div className="flex px-1 w-full border-1 border-black  border-opacity-20 rounded-tl-2xl justify-between items-center">
                    {user?.role === Roles.ADMIN ||
                    user?.role === Roles.MANAGER ? (
                      <div className="w-full">
                        <div className="flex justify-center items-center">
                          <UserCircleIcon
                            color="black"
                            className="m-1 p-1"
                            width={32}
                          />
                          <StyledText className="text-base md:text-lg  font-semibold">
                            {user?.nombre}
                          </StyledText>
                        </div>
                      </div>
                    ) : (
                      <div className="flex flex-col justify-center items-center w-full mr-3 py-2">
                        <div className="flex justify-center items-center">
                          <UserCircleIcon
                            color="black"
                            className="m-0 p-0"
                            width={28}
                          />
                          &nbsp;
                          <StyledText className="text-base md:text-lg  font-semibold">
                            {user?.nombre}
                          </StyledText>
                        </div>

                        <div className="flex w-full pl-2  justify-center items-center">
                          <StyledText className="text-base pr-2 md:text-lg">
                            Panimillas disponibles:
                          </StyledText>
                          <StyledText
                            className="text-base md:text-lg"
                            weight="700"
                          >
                            <SeparatorNumber
                              number={user?.millas_disponibles_canje || 0}
                            />
                          </StyledText>
                        </div>
                      </div>
                    )}
                    <div className="bg-moderna_yellow">
                      {loading ? (
                        <Spinner size="sm" />
                      ) : (
                        <button
                          className="flex-1 flex mr-1"
                          onClick={() => {
                            setOpenModal(true);
                          }}
                        >
                          <ArrowLeftEndOnRectangleIcon
                            color="white"
                            className="m-1"
                            width={36}
                          />
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
            <CustomModal
              isOpen={openModal}
              classname="w-[45vh]"
              onAccept={() => {
                signOut(), setOpenModal(false);
              }}
              onClose={() => {
                setOpenModal(false);
              }}
              title="¿Desea cerrar sesión?"
            />
          </>
        )}
      </Disclosure>
    </div>
  );
}
