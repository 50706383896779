import name from "../../assets/titulo.png";
import logo from "../../assets/Logo.png";
import { useContext } from "react";
import { SessionContext } from "../../modules/authentication/context/SessionProvider";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

const BANNED_ROUTES = ["/", "/*"];

export default function Header({ title, updateIsValid }: any) {
  const location = useLocation();
  const [isValid, setIsValid] = useState<Boolean>(true);
  useEffect(() => {
    setIsValid(updateIsValid);
  }, [updateIsValid]);

  if (BANNED_ROUTES.includes(location.pathname)) {
    return null;
  }

  return (
    <div className="h-auto ">
      <div className=" flex flex-row justify-between h-24 bg-moderna_red">
        <div className="flex sm:flex-1 min-[360px]:flex-auto justify-start items-center">
          <img
            src={name}
            alt="club"
            className="w-64 sm:w-96 h-full 2xl: object-cover"
          />
        </div>
        <div className="flex flex-auto justify-end items-center w-1/3 relative">
          <img
            src={logo}
            alt="chef"
            className="w-64 h-full object-contain absolute top-1.5"
          />
        </div>
      </div>
    </div>
  );
}
