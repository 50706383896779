import { makeRequest } from "../../common/request";
import { URL_QUERY_OTP } from "../serviceurl";

export const sendOtp = async (identification: string) => {
  try {
    const response = await makeRequest(
      "POST",
      URL_QUERY_OTP,
      null,
      {
        operation: "Q",
        identificacion: identification,
      }
    );
    return response;
  } catch (error) {
  }
};

export const validOtp = async (idClient: number, otpRequest: string) => {
  try {
    const response = await makeRequest(
      "POST",
      URL_QUERY_OTP,
      null,
      {
        operation: "V",
        id_cliente: idClient,
        otpRequest: otpRequest,
      }
    );
    return response;
  } catch (error) {
  }
};
