import React, { ReactNode, useContext } from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "@nextui-org/react";
import { SessionContext } from "../../modules/authentication/context/SessionProvider";

interface EditableModalProps {
  isOpen?: boolean;
  onOpenChange?: (open: boolean) => void;
  header?: ReactNode;
  body?: ReactNode;
  footer?: ReactNode;
  isDismissable?: boolean;
  isKeyboardDismissDisabled?: boolean;
  onClose?: () => void;
  buttonName1?: string;
  buttonName2?: string;
}

const EditableModal: React.FC<EditableModalProps> = ({
  header,
  body,
}) => {
  const { handleOpenModal, dataModal } = useContext(SessionContext);
  return (
    <>
      {dataModal.state && (
        <Modal
          size={"lg"}
          isOpen={true}
          placement="center"
          className="z-10"
          onClose={() => {
            if (dataModal?.onClick) {
              dataModal?.onClick();
            }
            handleOpenModal({
              state: false,
              body: null,
              header: null,
              onClick: null,
            });
          }}
          scrollBehavior="inside"
        >
          <ModalContent>
            {(onClose) => (
              <>
                <ModalHeader className="flex flex-col gap-1 justify-center items-center">
                  {dataModal.header &&
                    typeof dataModal.header === "function" &&
                    dataModal.header()}
                  {header}
                </ModalHeader>
                <ModalBody>
                  {dataModal.body &&
                    typeof dataModal.body === "function" &&
                    dataModal.body()}
                  {body}
                </ModalBody>
                <ModalFooter></ModalFooter>
              </>
            )}
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default EditableModal;
