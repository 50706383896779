import { makeRequest } from "../../common/request";
import { URL_QUERY_OPERATIONS } from "../serviceurl";

export const getAllCategoriesInDB = async () => {
  try {
    const response = await makeRequest(
      "POST",
      URL_QUERY_OPERATIONS,
      null,
      {
        operation: "S",
        table: "gmc_categoria",
      }
    );
    return response;
  } catch (error) {
  }
};

export const getAddCategoriesInDB = async (values: Array<string>) => {
  try {
    const response = await makeRequest(
      "POST",
      URL_QUERY_OPERATIONS,
      null,
      {
        operation: "I",
        table: "gmc_categoria",
        field: [
          "nombre_categoria",
          "au_id_usuario_creacion",
          "au_fecha_modificacion",
          "au_fecha_creacion"
        ],
        value: values
      }
    );
    return response;
  } catch (error) {
  }
};

export const getUpdateCategoriesInDB = async (
  values: Array<string>,
  conditionField: string,
  conditionValue: string
) => {
  try {
    const response = await makeRequest(
      "POST",
      URL_QUERY_OPERATIONS,
      { "Content-Type": "application/json" },
      {
        "operation": "U",
        "table": "gmc_categoria",
        "field": [
          "nombre_categoria"
        ],
        value: values,
        conditionField: conditionField,
        condition: "=",
        conditionValue: conditionValue,
      }
    );
    return response;
  } catch (error) {
  }
};

export const getDeleteCategoriesInDB = async (id: number) => {
  try {
    const response = await makeRequest(
      "POST",
      URL_QUERY_OPERATIONS,
      { "Content-Type": "application/json" },
      {
        operation: "D",
        table: "gmc_categoria",
        field: "id_categoria",
        condition: "=",
        value: id,
      }
    );
    return response;
  } catch (error) {
  }
};