const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID || "f9c3fe85-6556-48cf-9dce-95669e2a6656",
    authority: "https://login.microsoftonline.com/d7060bcc-bb26-4708-a91d-88362a86ae35",
    redirectUri: process.env.REACT_APP_URL_DEPLOYED,
  },
  cache: {
    cacheLocation: "localStorage", 
    storeAuthStateInCookie: true, 
  },
  system: {
    loggerOptions: {
      loggerCallback: () => {
        return;
      },
      piiLoggingEnabled: false,
    },
    accountMode: "SINGLE", },
};

const loginRequest = {
  scopes: ["User.Read", "Files.ReadWrite.All"],
  prompt: "select_account",
};

const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
};

const msal = {
  config: msalConfig,
  request: loginRequest,
  graphconfig: graphConfig,
};

export default msal;