function signInUser(identification: string) {
  return null;
}
function sendCodeUser(identification: string) {
  return null;
}

const auth = { signInUser, sendCodeUser };

export { auth };
