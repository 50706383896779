import { createContext } from "react";
import useBonus from "../hooks/useBonus";

const initialValues: any = {
  bonus: [],
};

const BonusContext = createContext(initialValues);

function BonusProvider(props: any) {
  const { bonus, editableBonus, setEditableBonus, handleSetDBBonus } =
    useBonus();
  return (
    <BonusContext.Provider
      value={{
        bonus,
        editableBonus,
        setEditableBonus,
        handleSetDBBonus,
      }}
    >
      {props.children}
    </BonusContext.Provider>
  );
}

export { BonusProvider, BonusContext };
