import { CSSProperties } from "react";

const SIZE = {
  HEADING: "3rem",
  SUBHEADING: "2.5rem",
  TITLE: "2rem",
  SUBTITLE: "1.75rem",
  LABEL: "1.25rem",
  BODY: "1rem",
  SMALL: "0.8rem",
  SMALLER: "0.75rem",
  SMALLEST: "0.6rem",
  TINY: "0.5rem",
  DEFAULT: "0.9rem",
};

type SizeProps = {
  size?:
  | "heading"
  | "subheading"
  | "title"
  | "subtitle"
  | "label"
  | "body"
  | "small"
  | "smaller"
  | "smallest"
  | "tiny";
};
type WeightProps = {
  weight?:
  | "100"
  | "200"
  | "300"
  | "400"
  | "500"
  | "600"
  | "700"
  | "800"
  | "900"
  | "light"
  | "bold"
  | "bolder"
  | "boldest";
};

type PositionProps =
  | { center?: never; right?: boolean; justify?: never }
  | { center?: boolean; right?: never; justify?: never }
  | { center?: never; right?: never; justify?: boolean };

type StyleProps = {
  italic?: boolean;
};

type DecorationProps = { decoration?: "underline" | "wavy" | "dotted" };
type TransformProps = {
  transform?: "lowercase" | "uppercase" | "capitalize";
};

type TextProps = PositionProps &
  WeightProps &
  StyleProps &
  DecorationProps &
  TransformProps &
  SizeProps & {
    type?: "error";
    children: string | string[] | number | any;
    color?: string;
    className?: string;
    style?: CSSProperties;
  };

export default function StyledText({
  center,
  right,
  children,
  justify,
  weight,
  italic,
  color,
  decoration,
  transform,
  style,
  className,
  size,
  type,
}: TextProps) {
  let _style: CSSProperties = {};
  switch (size) {
    case "heading": {
      _style.fontSize = SIZE.HEADING;
      break;
    }
    case "subheading": {
      _style.fontSize = SIZE.SUBHEADING;
      break;
    }
    case "title": {
      _style.fontSize = SIZE.TITLE;
      break;
    }
    case "subtitle": {
      _style.fontSize = SIZE.SUBTITLE;
      break;
    }
    case "label": {
      _style.fontSize = SIZE.LABEL;
      break;
    }
    case "body": {
      _style.fontSize = SIZE.BODY;
      break;
    }
    case "small": {
      _style.fontSize = SIZE.SMALL;
      break;
    }
    case "smaller": {
      _style.fontSize = SIZE.SMALLER;
      break;
    }
    case "smallest": {
      _style.fontSize = SIZE.SMALLEST;
      break;
    }
    case "tiny": {
      _style.fontSize = SIZE.TINY;
      break;
    }
    default: {
      _style.fontSize = SIZE.DEFAULT;
    }
  }

  if (center) _style.textAlign = "center";
  else if (right) _style.textAlign = "right";
  else if (justify) _style.textAlign = "justify";

  _style.fontWeight = weight;
  if (italic) _style.fontStyle = "italic";

  _style.textDecoration = decoration;
  _style.textTransform = transform;

  if (color) _style.color = color;
  if (type === "error") {
    _style.color = "red";
  }
  if (style) {
    _style = { ..._style, ...style };
  }
  return (
    <h1 style={style ? _style : {}} className={`font-main ${className}`} >
      {children}
    </h1>
  );
}
