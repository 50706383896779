import { useReducer } from "react";
import {
    ActionTypes,
    IReducer,
    Clients,
    ReducerAction,
} from "../interface/IClients.d";
import { getAllClientsInDB } from "../../../services/clientes/getallClients";

function reducer(state: IReducer, reducerAction: ReducerAction) {
    const { action } = reducerAction;
    switch (action) {
        case ActionTypes.SET_CLIENTS: {
            return { ...state, clients: reducerAction.payload };
        }
        case ActionTypes.SET_EDIT_CLIENTS: {
            return { ...state, editableClients: reducerAction.payload };
        }
        default: {
            return { ...state };
        }
    }
}

const initialState: IReducer = {
    clients: [],
    editableClients: null,
};

export default function useClients() {
    const [{ clients, editableClients }, dispatch] = useReducer(
        reducer,
        initialState
    );

    const handleSetDBClients = async () => {
        const allDBClients = await getAllClientsInDB();
        dispatch({
            action: ActionTypes.SET_CLIENTS,
            payload: allDBClients?.data?.recordset,
        });
    };

    const setEditableClients = (clients: Clients) => {
        dispatch({
            action: ActionTypes.SET_EDIT_CLIENTS,
            payload: clients,
        });
    };

    return { clients, editableClients, setEditableClients, handleSetDBClients };
}
