import React, { createContext } from "react";
import useSession from "../hooks/useSession";
import {
  DataModal,
  ISession,
  ISessionProvider,
  User,
} from "../interfaces/ISession.d";
import EditableModal from "../../../component/Modal/Modal";

const initialValues: ISession = {
  sendCode: (arg0: string) => { },
  signIn: (arg0: boolean) => { },
  signInAdmin: () => { },
  error: null,
  isSignedIn: false,
  isValidDNI: false,
  isValidOTP: false,
  loading: false,
  provitionalData: { telefono: null, id_cliente: null },
  setup: { user: false, admin: false },
  setupFunction: () => { },
  signOut: (arg0?: boolean) => { },
  setUser: () => { },
  user: null,
  isSessionActive: null,
  setLoading: (arg0: boolean) => { },
  handleVerifyByID: (arg0: string) => { },
  handleSendOtp: (arg0: string) => { },
  handleValidOtp: (arg0: number, arg1: string) => { },
  handleLogin: (arg0: User) => { },
  handleAceptTYC: (arg0: number) => { },
  dataModal: { state: false, body: null, header: null, onClick: null },
  handleOpenModal: (arg0: DataModal) => { },
  setError: () => { },
  handleUpdateStateAccount: () => { },
  handleSetSessionActive: () => { },
  saveSharepoinToken: () => { },
};

const SessionContext = createContext<ISessionProvider>(initialValues);

function SessionProvider(props: any) {
  const session = useSession();

  return (
    <SessionContext.Provider value={session}>
      <EditableModal />
      {props.children}
    </SessionContext.Provider>
  );
}

export { SessionContext, SessionProvider };
