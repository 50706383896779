import { makeRequest } from "../../common/request";
import { URL_QUERY_TOKEN } from "../serviceurl";

export const getToken = async () => {
  try {
    const response = await makeRequest(
      "POST",
      URL_QUERY_TOKEN,
      null,
      {}
    );
    return response;
  } catch (error) {
  }
};
