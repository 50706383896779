import { createContext } from "react";
import useClients from "../hooks/useClients";

const initialValues: any = {
    clients: [],
};

const ClientsContext = createContext(initialValues);

function ClientsProvider(props: any) {
    const { clients, editableClients, setEditableClients, handleSetDBClients } =
        useClients();
    return (
        <ClientsContext.Provider
            value={{
                clients,
                editableClients,
                setEditableClients,
                handleSetDBClients
            }}
        >
            {props.children}
        </ClientsContext.Provider>
    );
}

export { ClientsProvider, ClientsContext };
