import { makeRequest } from '../../common/request';
import { postAxios } from '../axios-service';
import { URL_GET_DATA, URL_QUERY_OPERATIONS } from '../serviceurl';

type serviceResponse = {
    result: boolean,
    error?: any,
    data?: any
}

export const getRolId = async (email: string): Promise<serviceResponse> => {
    try {
        const response = await postAxios(URL_GET_DATA,
            {
                table: "gmc_usuario",
                type: "correo",
                value: email,
                join: "inner",
                commonField: "id_rol",
                joinTable: "gmc_rol",
                requiredFields: [
                    "nombre_rol"
                ]
            }
        );
        if (!(response?.length > 0)) {
            return { result: true, data: -1 }
        }
        return { result: true, data: response[0] }

    } catch (error) {
        return { result: false, error }
    }

}

export const updateTermsConditions = async () => {
    try {
        const response = await makeRequest(
            "POST",
            URL_QUERY_OPERATIONS,
            null,
            {
                operation: "U",
                table: "gmc_cliente",
                field: ["fecha_aceptacion_tyc"],
                value: ["null"]
            }
        );
        return response;
    } catch (error) {
    }
};

export const getUSerInfo = async (email: string): Promise<serviceResponse> => {
    const response = await postAxios(URL_GET_DATA,
        {
            table: "gmc_usuario",
            type: "correo",
            value: email,
            join: "inner",
            commonField: "id_rol",
            joinTable: "gmc_rol",
            requiredFields: [
                "nombre_rol"
            ]
        }
    );

    return response[0]



}