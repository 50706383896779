import React from "react";
import PDFPrinter from "./PDFPrinter";
import { FaFastBackward } from "react-icons/fa";
import { FaForwardFast } from "react-icons/fa6";
import { FaForward } from "react-icons/fa6";
import { FaBackward } from "react-icons/fa";
import { MdOutlineZoomIn } from "react-icons/md";
import { MdOutlineZoomOut } from "react-icons/md";
import { FaFileDownload } from "react-icons/fa";

const ControlPanel = (props: any) => {
  const { file, pageNumber, numPages, setPageNumber, scale, setScale } = props;

  const isFirstPage = pageNumber === 1;
  const isLastPage = pageNumber === numPages;

  const firstPageClass = isFirstPage ? "disabled" : "clickable";
  const lastPageClass = isLastPage ? "disabled" : "clickable";

 
  const goToPreviousPage = () => {
    if (!isFirstPage) setPageNumber(pageNumber - 1);
  };
  const goToNextPage = () => {
    if (!isLastPage) setPageNumber(pageNumber + 1);
  };
 

  const isMinZoom = scale < 0.6;
  const isMaxZoom = scale >= 1;

  const zoomOutClass = isMinZoom ? "disabled" : "clickable";
  const zoomInClass = isMaxZoom ? "disabled" : "clickable";

  const zoomOut = () => {
    if (!isMinZoom) setScale(scale - 0.1);
  };

  const zoomIn = () => {
    if (!isMaxZoom) setScale(scale + 0.1);
  };

  return (
    <div className="my-4 rounded-sm p-2 justify-content-between items-center bg-white flex flex-row w-auto border border-gray-300 shadow-md">
      <div className="justify-content-between items-center flex flex-row w-auto">
        <FaBackward
          color="black"
          className={`fas fa-backward mx-3 clickable ${firstPageClass}`}
          onClick={goToPreviousPage}
          style={{ cursor: "pointer", transition: "color 0.3s" }}
        />
        <span className="text-black text-base">
            Pág. {pageNumber} {" "}
         
          / {numPages}
        </span>
        <FaForward
          color="black"
          className={`fas fa-forward mx-3 clickable ${lastPageClass}`}
          onClick={goToNextPage}
          style={{ cursor: "pointer", transition: "color 0.3s" }}
        />
      </div>
      <div className="justify-content-between align-items-baseline flex flex-row items-center">
        <MdOutlineZoomOut
          color="black"
          className={`fas fa-search-minus mx-3 clickable ${zoomOutClass}`}
          onClick={zoomOut}
          style={{ cursor: "pointer", transition: "color 0.3s" }}
        />
        <span className="text-black text-base">{(scale * 100).toFixed()}%</span>
        <MdOutlineZoomIn
          color="black"
          className={`fas fa-search-plus mx-3 clickable ${zoomInClass}`}
          onClick={zoomIn}
          style={{ cursor: "pointer", transition: "color 0.3s" }}
        />
      </div>
      <div className="items-center justify-center">
        <a href={file} download={true} title="download">
          <FaFileDownload
            color="black"
            className="fas fa-file-download clickable"
            style={{ cursor: "pointer", transition: "color 0.3s" }}
          />
        </a>
      </div>
    </div>
  );
};

export default ControlPanel;
