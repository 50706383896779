import { Spinner } from "@nextui-org/react";

export default function Loading() {
  return (
    <div className="w-full h-dvh flex justify-center items-center">
      <Spinner
        label="Cargando..."
        color="warning"
        size="lg"
        className="font-main"
      />
    </div>
  );
}

export const LoadingMonthsw = () => {
  return (
    <div className="w-full h-full flex justify-center items-center">
      <Spinner
        label="Cargando..."
        color="warning"
        size="lg"
        className="font-main"
      />
    </div>
  );
};
