import axios, { AxiosResponse, AxiosError } from 'axios';

export const makeRequest = async (
  method: string,
  url: string,
  headers: any = {},
  data: any = {},
  retry: boolean = true
): Promise<AxiosResponse<any, any>> => {

  function newAbortSignal(seconds: number) {
    const abortController = new AbortController();
    setTimeout(() => {
      abortController.abort();
    }, seconds || 0);

    return abortController.signal;
  }

  try {
    const defaultHeaders = {
      "Content-Type": "application/json",
    };
    const mergedHeaders = headers
      ? { ...defaultHeaders, ...headers }
      : defaultHeaders;
    const response = await axios({
      method,
      url,
      headers: mergedHeaders,
      data,
      signal: newAbortSignal(15000),
    });
    return response;
  } catch (error: any) {
    if (axios.isCancel(error)) {
      if (retry) {
        return makeRequest(method, url, headers, data, false);
      }
    }
    return {
      status: (error as AxiosError).response?.status || 500,
      statusText:
        (error as AxiosError).response?.statusText || "Error de servidor",
      headers: (error as AxiosError).response?.headers || {},
      config: { headers: {} },
      data: error?.response?.data,
    } as AxiosResponse<any, any>;
  }
};
