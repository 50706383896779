import { useEffect, useReducer } from "react";
import {
  ActionTypes,
  IReducer,
  Bonus,
  ReducerAction,
} from "../interfaces/IBonus";
import { getAllBonusInDB } from "../../../services/bonus/getAllBonusInDB";

function reducer(state: IReducer, reducerAction: ReducerAction) {
  const { action } = reducerAction;
  switch (action) {
    case ActionTypes.SET_BONUS: {
      return { ...state, bonus: reducerAction.payload };
    }
    case ActionTypes.SET_EDIT_BONUS: {
      return { ...state, editableBonus: reducerAction.payload };
    }
    default: {
      return { ...state };
    }
  }
}

const initialState: IReducer = {
  bonus: [],
  editableBonus: null,
};

export default function useBonus() {
  const [{ bonus, editableBonus }, dispatch] = useReducer(
    reducer,
    initialState
  );

  const handleSetDBBonus = async () => {
    const allDBProducts = await getAllBonusInDB();
    const bonus = allDBProducts?.data?.recordset;
    bonus?.sort(compararObjetos);
    const orderedBonds = bonus;
    dispatch({
      action: ActionTypes.SET_BONUS,
      payload: allDBProducts?.data?.recordset,
    });
  };

  const compararObjetos = (a: any, b: any): number => {
    const orden: { [tipo: string]: number } = { T: 0, M: 1, B: 2 };
    return orden[a.tipo] - orden[b.tipo];
  }

  const setEditableBonus = (bonus: Bonus) => {
    dispatch({
      action: ActionTypes.SET_EDIT_BONUS,
      payload: bonus,
    });
  };

  return { bonus, editableBonus, setEditableBonus, handleSetDBBonus };
}
