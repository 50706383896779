import { makeRequest } from "../../common/request";
import { URL_QUERY_OPERATIONS } from "../serviceurl";

export const getAllClientsInDB = async () => {
    try {
        const response = await makeRequest(
            "POST",
            URL_QUERY_OPERATIONS,
            null,
            {
                operation: "S",
                table: "gmc_usuario",
                join: "inner",
                commonField: "id_rol",
                joinTable: "gmc_rol",
                requiredFields: [
                    "nombre_rol"
                ]
            }
        );
        return response;
    } catch (error) {
    }
};
