import { makeRequest } from "../../common/request";
import { URL_QUERY_OPERATIONS } from "../serviceurl";

export const getAllRolInDB = async () => {
    try {
        const response = await makeRequest(
            "POST",
            URL_QUERY_OPERATIONS,
            null,
            {
                operation: "S",
                table: "gmc_rol",
            }
        );
        return response;
    } catch (error) {
    }
};
