import { makeRequest } from "../../common/request";
import { URL_QUERY_OPERATIONS } from "../serviceurl";

export const updateSesion = async (rol: string, sesion: number, id: number) => {
    try {
        const response = await makeRequest(
            "POST",
            URL_QUERY_OPERATIONS,
            { "Content-Type": "application/json" },
            {
                operation: "U",
                table: rol === "ADMIN" ? "gmc_usuario" : rol === "CLI" && "gmc_cliente",
                "field": [
                    "sesion"
                ],
                "value": [
                    sesion
                ],
                "conditionField": rol === "ADMIN" ? "id_usuario" : rol === "CLI" && "id_cliente",
                "condition": "=",
                "conditionValue": id

            }
        );
        return response;
    } catch (error) {
        return null
    }
};
