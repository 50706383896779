import {
  Suspense,
  lazy,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Footer from "./component/Footer/footer";
import Header from "./component/Header/header";
import { SessionContext } from "./modules/authentication/context/SessionProvider";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { getRolId } from "./services/auth-service/admin-service";
import Navbar from "./component/Navbar/Navbar";
import TermsConditions from "./page/TermsConditions/TermsConditions";
import {
  ERROR_TYPES,
  Roles,
} from "./modules/authentication/interfaces/ISession.d";
import Loading from "./component/Spinner/spinner";
import CustomModal from "./component/Modal/ModalCustom";
import { Skeleton } from "@nextui-org/react";
import SignIn from "./page/admin/sign-in/sign-in";
import useProducts from "./modules/products/hooks/useProducts";
import useBonus from "./modules/bonus/hooks/useBonus";
import useClients from "./modules/clientes/hooks/useClients";

const ListBonus = lazy(() => import("./page/list-bonus/listBonus"));
const Principal = lazy(() => import("../src/page/home/inicio"));
const Points = lazy(() => import("./page/points/points"));
const Details = lazy(() => import("./page/details/details"));
const Addproduct = lazy(() => import("./page/add-product/addproduct"));
const Clientes = lazy(() => import("./page/clientes/clientes"));
const Otp = lazy(() => import("./page/otp/otp"));
const NotFound = lazy(() => import("./page/not-found/notfound"));
const AdminTermsConditions = lazy(
  () => import("./page/TermsConditions/AdminTermsConditions")
);

const ProductExchange = lazy(
  () => import("./page/products-exchange/products-exchange")
);
const Listproduct = lazy(() => import("./page/list-product/listproduct"));

function App() {
  const [openModal, setOpenModal] = useState(false);

  const {
    isSignedIn,
    signOut,
    isSessionActive,
    user,
    setUser,
    setError,
    setup,
    setupFunction,
    saveSharepoinToken,
    handleSetSessionActive,
  } = useContext(SessionContext);
  const { accounts } = useMsal();
  const { handleSetDBProducts, handleSetDBCategories, handleSetDBRol } =
    useProducts();
  const { handleSetDBBonus } = useBonus();
  const { handleSetDBClients } = useClients();

  const isAuthenticated = useIsAuthenticated();
  useEffect(() => {
    verifyAdmin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, accounts]);
  useEffect(() => {
    if (isSessionActive !== null && !isSessionActive) {
      setOpenModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSessionActive]);

  useEffect(() => {
    if (isSessionActive) {
      let timeout: NodeJS.Timeout | undefined;

      const resetTimer = () => {
        if (timeout) {
          clearTimeout(timeout);
        }
        timeout = setTimeout(() => {
          signOut(true);
          handleSetSessionActive(false);
        }, 30 * 60 * 1000);
      };

      const clearTimer = () => {
        if (timeout) {
          clearTimeout(timeout);
          timeout = undefined;
        }
      };

      const events = [
        "mousedown",
        "mousemove",
        "keypress",
        "scroll",
        "touchstart",
      ];

      for (const event of events) {
        window.addEventListener(event, resetTimer);
      }

      resetTimer();

      return () => {
        for (const event of events) {
          window.removeEventListener(event, resetTimer);
        }
        clearTimer();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSessionActive, user]);

  const verifyAdmin = useCallback(async () => {
    try {
      if (isAuthenticated && accounts[0]) {
        const response = await getRolId(accounts[0]?.username);
        if (
          (response.result && response?.data?.id_rol === 2) ||
          response?.data?.id_rol == 1
        ) {
          saveSharepoinToken();
          setUser({
            id_usuario: response?.data.id_usuario,
            correo: accounts[0].username,
            nombre: accounts[0].name || accounts[0].username,
            role: response?.data?.id_rol === 1 ? Roles.MANAGER : Roles.ADMIN,
          });
          setupFunction({ admin: true });
          handleSetSessionActive(true);
          return;
        }
        setError({ message: "No tienes acceso", type: ERROR_TYPES.NOT_ADMIN });
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }
      if (accounts.length < 1) {
        setupFunction({ admin: true });
      }
    } catch (error) {
      setupFunction({ admin: true });
      setError({ message: "No tienes acceso", type: ERROR_TYPES.NOT_ADMIN });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, accounts, setUser, setError, setupFunction]);

  if (!setup.admin || !setup.user) {
    return <Loading />;
  }

  if (!isSignedIn) {
    return (
      <Routes>
        <Route path="/" element={<Principal />} />
        <Route path="/admin" element={<SignIn />} />
        <Route path="/validacion" element={<Otp />} />
        <Route path="/terminosCondiciones" element={<TermsConditions />} />
        <Route path="/*" element={<NotFound />} />
      </Routes>
    );
  }

  return (
    <div
      style={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}
    >
      <Suspense fallback={<Skeleton className="h-full w-full" />}>
        <div style={{ flex: 1 }}>
          <Header />
          <Navbar />
          <Routes>
            <Route
              path="/"
              element={
                <Navigate
                  to={
                    user?.role === Roles.USER ? "/inicio" : "/admin/listabonos"
                  }
                />
              }
            />
            <Route
              path="/admin"
              element={
                <Navigate
                  to={
                    user?.role === Roles.USER ? "/inicio" : "/admin/listabonos"
                  }
                />
              }
            />
            {(user?.role === Roles.ADMIN || user?.role === Roles.MANAGER) &&
              AdminPage()}
            {user?.role === Roles.USER && UserPage()}
            <Route path="/*" element={<NotFound />} />
          </Routes>
        </div>
        <Footer />
        <CustomModal
          onlyCloseButton
          onClose={() => {}}
          noExit
          dismissable
          onCloseEvent={() => {
            signOut();
            setOpenModal(false);
          }}
          isOpen={openModal}
          message="Tu sesión ha expirado. Por favor, vuelve a ingresar al sistema."
        />
      </Suspense>
    </div>
  );
}

const AdminPage = () => {
  return (
    <>
      <Route path="/admin/listabonos" element={<ListBonus />} />
      <Route path="/admin/ingresarproducto" element={<Addproduct />} />
      <Route path="/admin/usuarios" element={<Clientes />} />
      <Route path="/admin/listaproducto" element={<Listproduct />} />
      <Route
        path="/admin/terminos-y-condiciones"
        element={<AdminTermsConditions />}
      />
    </>
  );
};

const UserPage = () => {
  return (
    <>
      <Route path="/inicio" element={<Points />} />
      <Route path="/canje" element={<ProductExchange />} />
      <Route path="/detalleProducto" element={<Details />} />
      <Route path="/detalles" element={<Details />} />
    </>
  );
};

export default App;
