import React, { useState } from "react";
import PDFReader from "./components/PDFReader";

export const PDFViewer: React.FC<{
  fileId: string;
  accepted: boolean;
  handleCheckboxChange?: any;
}> = ({ fileId, handleCheckboxChange, accepted }) => {
  return (
    <PDFReader
      file={fileId}
      handleCheckboxChange={handleCheckboxChange}
      accepted={accepted}
    />
  );
};

export const AdminPDFViewer: React.FC<{
  defaultUrl: string;
  pdfFile?: File;
  disableCheckBox?: boolean;
}> = ({ defaultUrl, pdfFile, disableCheckBox }) => {
  const [pdfUrl, setPdfUrl] = useState<string | null>(null);

  React.useEffect(() => {
    if (pdfFile) {
      const url = URL.createObjectURL(pdfFile);
      setPdfUrl(url);
    }
  }, [pdfFile]);

  return (
    <PDFReader
      file={pdfUrl || defaultUrl}
      accepted={false}
      disableCheckBox
      handleCheckboxChange={() => { }}
    />
  );
};
