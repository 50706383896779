import React from "react";
import * as Yup from "yup";
import { Button } from "@nextui-org/react";
import { ErrorMessage } from "formik";
interface FormFieldProps {
  label: string;
  name: string;
  type: string;
  value: string;
  onChange: (name: string, value: string) => void;
  error?: string;
}

interface FormButtonsProps {
  onCancel?: () => void;
  onSave?: () => void;
  disabled?: boolean;
  add?: () => void;
  title?: string;
}
export const FormField: React.FC<FormFieldProps> = ({
  label,
  name,
  type,
  value,
  onChange,
  error,
}) => (
  <div className="flex flex-col w-full">
    <label htmlFor={name} className="block text-sm font-medium text-gray-700">
      {label}
    </label>
    <input
      type={type}
      id={name}
      value={value}
      onChange={(e) => onChange(name, e.target.value)}
      className={`mt-1 p-2 border rounded-md w-full ${value ? "" : ""}`}
    />
    {error && (
      <ErrorMessage
        name={name}
        component="div"
        className="text-red-500 text-sm"
      />
    )}
  </div>
);

export const FormButtons: React.FC<FormButtonsProps> = ({
  onSave,
  onCancel,
  disabled,
}) => (
  <div className="flex justify-center space-x-8 mt-3">
    <Button color="primary" className="hover:bg-red_yellow" onClick={onCancel}>
      Cancelar
    </Button>
    <Button
      disabled={disabled}
      type="submit"
      color="secondary"
      className={`hover:bg-moderna_yellow`}
      onClick={onSave}
    >
      Guardar
    </Button>
  </div>
);

export const validations = () => {
  return <div>validations</div>;
};

export const validationSchema = Yup.object().shape({
  titulo: Yup.string().test(
    "notEmpty",
    "El título no puede estar vacío",
    (value) => !!value && value.trim() !== ""
  ),
  panimillas: Yup.string()
    .test(
      "notEmpty",
      "Panimillas no puede estar vacío",
      (value) => !!value && value.trim() !== ""
    )
    .matches(/^[0-9]+$/, "Panimillas solo debe contener números"),
  unidad: Yup.string().test(
    "notEmpty",
    "La unidad no puede estar vacía",
    (value) => !!value && value.trim() !== ""
  ),
  tipo: Yup.string()
    .test(
      "notEmpty",
      "El tipo no puede estar vacío",
      (value) => !!value && value.trim() !== ""
    )
    .matches(/^[TBM]$/, "El tipo solo debe ser T, P o S"),
  correo: Yup.string()
    .test(
      "notEmpty",
      "El tipo no puede estar vacío",
      (value) => !!value && value.trim() !== ""
    )
    .email("El correo debe ser válido")
    .required("El correo es requerido"),
  nombreProducto: Yup.string().required("El nombre del producto es requerido"),
  costo: Yup.number()
    .required("El costo es requerido")
    .positive("El costo debe ser mayor que 0"),
  proveedorCorreo: Yup.string()
    .required("El campo no debe estar vacío"),
  descripcion: Yup.string().required(
    "La descripción del producto es requerida"
  ),
  imagen: Yup.mixed().required("Por favor añade una imagen"),
  id_categoria: Yup.number().required("Debes escoger una categoría"),
  codigoSAP: Yup.string().required("El campo no debe estar vacío"),
});

export const GetDate = () => {
  const fechaActual: Date = new Date();
  const dia: number = fechaActual.getDate();
  const mes: number = fechaActual.getMonth() + 1;
  const año: number = fechaActual.getFullYear();

  const fechaFormateada: string = `${dia < 10 ? "0" : ""}${dia}/${
    mes < 10 ? "0" : ""
  }${mes}/${año}`;

  return fechaFormateada;
};

export const ButtonAdd: React.FC<FormButtonsProps> = ({ add, title }) => {
  return (
    <div className=" md:mx-3 flex justify-start items-center">
      <button
        type="button"
        className="rounded-lg py-1  h-14 border-2 border-gray-300 shadow-md px-4 flex items-center w-full bg-secondary text-white font-semibold  gap-x-1 "
        onClick={add}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          className="w-4 h-4"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12 4.5v15m7.5-7.5h-15"
          />
        </svg>
        <span>{title ? title : "Agregar"}</span>
      </button>
    </div>
  );
};

export const TYPES = [
  { id_tipo: 1, nombre_tipo: "T" },
  { id_tipo: 2, nombre_tipo: "M" },
  { id_tipo: 3, nombre_tipo: "B" },
];
interface NumberProps {
  number: number | string;
}

export const SeparatorNumber: React.FC<NumberProps> = ({ number }) => {
  if (number === undefined || number === null) {
    return <span>0</span>; 
  }
  const numberCommas = (x: number) => {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  const numberToFormat =
    typeof number === "string" ? parseFloat(number) : number;
  return <span>{numberCommas(numberToFormat)}</span>;
};
export const HEADER = ["Titulo", "Panimillas", "Unidad", "Tipo", "Acciones"];
