import { makeRequest } from "../../common/request";
import { URL_GET_DATA } from "../serviceurl";

export const getClientInDB = async (id_cliente: number) => {
  try {
    const response = await makeRequest(
      "POST",
      URL_GET_DATA,
      { "Content-Type": "application/json" },
      {
        table: "gmc_cliente",
        type: "id_cliente",
        value: id_cliente,
      }
    );
    return response;
  } catch (error) {
  }
};
