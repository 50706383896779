import React, { ReactNode } from "react";
import Footer from "../Footer/footer";
import { Modal, ModalBody, ModalContent, ModalHeader, Radio, RadioGroup } from "@nextui-org/react";

interface EditableModalProps {
  isOpen?: boolean;
  onOpenChange?: (open: boolean) => void;
  header?: ReactNode;
  body?: ReactNode;
  footer?: ReactNode;
  isDismissable?: boolean;
  isKeyboardDismissDisabled?: boolean;
  onClose?: () => void;
  classname?: string;
  size?: "sm" | "md" | "lg" | "xl" | "2xl" | "xs" | "3xl" | "4xl" | "5xl" | "full";
}

const EditableModal: React.FC<EditableModalProps> = ({
  isOpen,
  onOpenChange,
  header,
  body,
  onClose,
  size,
  classname
}) => {
  return (
    <>
      <Modal
        size={size ? size : "5xl"}
        isOpen={isOpen}
        scrollBehavior="inside"
        onOpenChange={onOpenChange}
        onClose={onClose}
        className={classname}
        placement="center"
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1 justify-center items-center">
                {header}
              </ModalHeader>
              <ModalBody>{body}</ModalBody>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

export default EditableModal;
