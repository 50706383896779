import { makeRequest } from "../../common/request";
import { URL_QUERY_OPERATIONS } from "../serviceurl";

export const updateAceptacion_tyc = async (id_cliente: number) => {
  try {
    const response = await makeRequest(
      "POST",
      URL_QUERY_OPERATIONS,
      { "Content-Type": "application/json" },
      {
        operation: "U",
        table: "gmc_cliente",
        field: ["fecha_aceptacion_tyc"],
        value: ["DATEADD(hour, -5, GETDATE())"],
        conditionField: "id_cliente",
        condition: "=",
        conditionValue: id_cliente,
      }
    );
    return response;
  } catch (error) {
  }
};
