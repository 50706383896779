import { CSSProperties, ReactNode } from "react";
import { motion } from "framer-motion";

type FramerProps = {
  children: ReactNode;
  delay?: number;
  duration?: number;
};
type FadeProps = {
  direction?: "top" | "left" | "rigth" | "bottom" | "in";
} & FramerProps;
type HoverProps = {
  scale?: number;
} & FramerProps;

type DefaultComponentProps = {
  className?: string;
  style?: CSSProperties;
};
function Hover({
  children,
  delay = 0,
  duration = 0.3,
  className,
  style,
  scale = 1.05,
}: HoverProps & DefaultComponentProps) {
  return (
    <motion.div
      className={className}
      style={style}
      initial={{ opacity: 1, y: 0 }}
      transition={{ delay, duration }}
      whileHover={{ scale, opacity: 1.2 }}
      whileTap={{ scale: 0.95 }}
    >
      {children}
    </motion.div>
  );
}

function Fade({
  children,
  delay = 0,
  duration = 0.5,
  className,
  style,
  direction = "top",
}: FadeProps & DefaultComponentProps) {
  let y = 0;
  let x = 0;
  if (direction === "bottom") {
    y = 50;
  } else if (direction === "top") {
    y = -50;
  }
  if (direction === "left") {
    x = 50;
  } else if (direction === "rigth") {
    x = -50;
  }

  return (
    <motion.div
      className={className}
      style={style}
      initial={{ opacity: 0, y, x, scale: direction === "in" ? 0.98 : 1 }}
      animate={{ opacity: 1, y: 0, x: 0, scale: 1 }}
      transition={{ delay, duration, type: "spring", bounce: 0.35 }}
      exit={{ scale: 0 }}
    >
      {children}
    </motion.div>
  );
}

const Framer = { Hover, Fade };

export default Framer;
