import { makeRequest } from "../../common/request";
import { URL_QUERY_OPERATIONS } from "../serviceurl";

export const getAllBonusInDB = async () => {
  try {
    const response = await makeRequest(
      "POST",
      URL_QUERY_OPERATIONS,
      { "Content-Type": "application/json" },
      {
        operation: "S",
        table: "gmc_bonus",
      }
    );
    return response;
  } catch (error) {
  }
};
